import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

export const LinkWrapper = styled("div")`
  ${({ theme }): SerializedStyles => css`
    display: inline-block;
    margin-top: 30px;
    ${theme.breakpoints.up("md")} {
      .link-arrow {
        display: inline-block;
        transition: ${theme.transitions.create("transform", {
          duration: theme.transitions.duration.complex,
        })};
      }
      &:hover {
        .link-arrow {
          transform: translateX(${theme.spacing(1)});
        }
      }
    }
  `}
`;
