import React from "react";
import { Typography } from "@mui/material";
import { Container } from "../containers";
import { Link } from "../links";
import { LinkWrapper } from "./styles";

export function NotFound(): React.ReactElement {
  return (
    <Container style={{ marginTop: "120px" }}>
      <Typography gutterBottom color="primary" variant="h2">
        Oops! <br />
      </Typography>
      <Typography gutterBottom color="primary" variant="h4">
        We can&apos;t seem to find the page you&apos;re looking for.
      </Typography>
      <LinkWrapper>
        <Link withArrow color="primary" to="/" variant="body1">
          Go back to homepage
        </Link>
      </LinkWrapper>
    </Container>
  );
}
