import React from "react";
import { NotFound } from "@italymondo/core-ui";
import { Layout } from "../components";

function NotFoundPage(): React.ReactElement {
  return (
    <Layout transparentHeader={false}>
      <NotFound />
    </Layout>
  );
}

export default NotFoundPage;
